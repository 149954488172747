import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { event } from 'utils/analytics';
import Md from 'utils/TextHelpers';
import CheckClaimCoverByDate from 'components/CheckCoverModal/CheckClaimCoverByDate';

import {
  TRIAGE_WRAPPER,
  COMBO_TYPES,
  FAKE_UPLOAD_ID
} from 'constants/constants';

import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';
import { appInsights } from 'ApplicationInsights';

class ComboQuestion extends Component {
    constructor(props) {
    super(props);
    this.state = {
      selectValue: '',
        selectChanged: false,
        showCheckClaimCover: false,
        selectedDate: '',
        selectedRadioItem: null,
        DetePopUpClose: false
    };
    this.selectedStyle = this.selectedStyle.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleButtonChange = this.handleButtonChange.bind(this);
    this.handleRadioClick = this.handleRadioClick.bind(this);
    this.dispatchGAEvent = this.dispatchGAEvent.bind(this);
    }

    handleItemSelected = (item) => {
        this.setState({ selectedItem: item });
    };  

  dispatchGAEvent(selectedItem) {
    event(GA_EVENTS.QUESTION, GA_ACTIONS.ANSWER, {
      label: `${this.props.guideWireField} - ${selectedItem}`
    });
    appInsights.trackEvent(
      {
        name:`${this.props.guideWireField} - ${selectedItem}`
      },
      {
        EventCategory: GA_EVENTS.QUESTION,
        EventAction: GA_ACTIONS.ANSWER,
        EventLabel: `${this.props.guideWireField} - ${selectedItem}`
      }
      )
  }

  selectedStyle(comboItem) {
    const isSelected =
      this.props.userAnswer && this.props.userAnswer.value === comboItem.value;
    return isSelected ? 'btn-amex-selected' : '';
  }

  handleOnChange(eventHandler) {
    const { value } = eventHandler.target;

    const selectedComboItem =
      value && this.props.properties.items.find(item => item.value === value);

    this.setState({
      selectValue: selectedComboItem
    });
    this.dispatchGAEvent(value);
    this.props.onUserAnswer(
      this.props.id,
      value,
      selectedComboItem.nextGroupId || this.props.nextGroup
    );
  }

  handleButtonChange(value, nextGroupId) {
    this.setState({
      selectValue: '',
      selectChanged: true
    });
    this.dispatchGAEvent(value);
    this.props.onUserAnswer(this.props.id, value, nextGroupId);
    if (
      this.props.activePage === TRIAGE_WRAPPER &&
      this.props.currentClaim &&
      this.props.currentClaim.benefitId &&
      this.props.currentClaim.benefitId !== null
    ) {
      const newClaim = {
        benefitId: null
      };
      this.props.updateClaimData(newClaim);
    }
    }

    handleRadioClick = (item) => {
       
        console.log(' reached handleadioclick => ', item);
        this.setState({ selectedRadioItem: item });
        
        if (item && item.value === 'properties') {            
            this.setState({
                showCheckClaimCover: false
            });
        }

        if (item && item.value === 'MytravelinsuranceincludedwithmyCard') {
           
            this.dispatchGAEvent(item.value);
            this.props.onUserAnswer(
                this.props.id,
                'MytravelinsuranceincludedwithmyCard'
            );
           
            this.setState({ showCheckClaimCover: true });            
        } else {
            debugger;
                this.dispatchGAEvent(item.value);
                this.props.onUserAnswer(
                    this.props.id,
                    item.value,
                    item.nextGroupId || this.props.nextGroup
                );            
        }
    };

  handleDateChange = (date) => {        
        this.setState({ selectedDate: date });
    };

    render() {

        const { showCheckClaimCover, selectedRadioItem } = this.state;
        if (showCheckClaimCover) {
            
            return (
               
                <CheckClaimCoverByDate                    
                    onRadioClick={this.handleRadioClick}
                    Title="checkcomboclaim2024"
                    selectedRadioItem={selectedRadioItem}
                />
            );
        }
    const grouped = this.props.properties.items.filter(item => item.isGrouped);
    const notGrouped = this.props.properties.items.filter(
      item => !item.isGrouped
    );
    const hasGrouped = grouped.length > 0;
    const hasNotGrouped = notGrouped.length > 0;
    const isRadioButton = this.props.properties.type === COMBO_TYPES.RADIO;

    const displayComboItems = notGrouped.map((item, index) => (
      <div className="flex-grow-1 p-2" key={item.label}>
        <button
          data-testId={`btn-comboQuestion-${index}`}
          type="button"
          className={`btn btn-outline-primary btn-lg btn-block btn-amex ${this.selectedStyle(
            item
          )}`}
          onClick={() =>
            this.handleButtonChange(
              item.value,
              item.nextGroupId || this.props.nextGroup
            )
          }
        >
          {item.label}
        </button>
      </div>
    ));
    const displayRadioButtons = (            
      <div className="col">
        {this.props.properties.items.map((item, index) => (
          <label
            data-testid="label-comboQuestion"
            className="radio-container"
            key={item.value}
                    >                        
                      {Md.render(item.label)}
            <input
              data-testid={`rb-comboQuestion-${index}`}
              type="radio"
                            name="radio"
                            value={item.value}
                            checked={this.state.selectedRadioItem === item}
                            
              onClick={() => this.handleRadioClick(item)}
            />
            <span className="checkmark" />
          </label>
        ))}
      </div>
    );

    const selectItemNew = this.props.properties.items.filter(
      item => item.value === this.props.answer && item.isGrouped
    );
    let valueNew = this.state.selectValue;
    if (
      selectItemNew.length &&
      !this.state.selectValue &&
      !this.state.selectChanged
    ) {
      [valueNew] = selectItemNew;
    }

    const newOptions = grouped.map((item, key) => (
      // eslint-disable-next-line react/no-array-index-key
      <option key={key} value={item.value} selected={key === 1}>
        {item.label}
      </option>
    ));

    const displayComboSelect = (
      <div className="col form-group combo-item mr-4">
        <select
          className="input-group-text mr-2 simple-select mt-2"
                value={this.state.selectValue.value}
          onChange={this.handleOnChange}
            >
                {/*{newOptions}*/}
                {grouped.map((item, key) => (
                    <option key={key} value={item.value} selected={key === 1}>
                        {item.label}
                    </option>
                ))}
        </select>
      </div>
    );

        return (
            <div className="d-flex flex-wrap row combo">
                {console.log("Rendering with counter value:")}
        {!isRadioButton && hasNotGrouped && displayComboItems}
        {!isRadioButton && hasGrouped && displayComboSelect}                
        {isRadioButton && displayRadioButtons}            
      </div>
    );
  }
}

ComboQuestion.propTypes = {
    onRadioClick: PropTypes.func,    
  answer: PropTypes.string,
  properties: PropTypes.shape({
    type: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  onUserAnswer: PropTypes.func.isRequired,
  userAnswer: PropTypes.shape({
    value: PropTypes.string,
    questionId: PropTypes.number,
    nextGroupId: PropTypes.number
  }),
  id: PropTypes.number,
  nextGroup: PropTypes.number,
  activePage: PropTypes.string,
  updateClaimData: PropTypes.func,
  currentClaim: PropTypes.shape({
    benefitId: PropTypes.number
  }),
  guideWireField: PropTypes.string
};

ComboQuestion.defaultProps = {
  id: FAKE_UPLOAD_ID,
  answer: '',
  userAnswer: undefined,
  nextGroup: null,
  updateClaimData: () => {},
  currentClaim: {},
  activePage: '',
  guideWireField: 'comboQuestion'
};

export default ComboQuestion;
