import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import HowToClaimApi from '../../api/HowToClaimApi';
import CardHowToClaimComponent from './CardHowToClaimComponent';
import HowToClaimInnerCards from './HowToClaimInnerCard';
import HowToClaimWeAsk from './HowToClaimWeAsk';
import HowToClaimYouProvide from './HowToClaimYouProvide';
import howToClaimLabels from './howToClaimLabels';
import CustomModal from '../../shared/components/CustomModal';

export class HowToClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      howToClaim: {},
      displayHowToClaim: false,
      displayContinueBtn: false,
      modalDeclineMsg: ''
    };

    this.setBenefit = this.setBenefit.bind(this);
    this.getDeclineModal = this.getDeclineModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

    componentDidMount() {
        debugger;
    const { properties, id, productId, hideTitle, claimStep } = this.props;

    // TODO: convert this to actions      
      HowToClaim.getHowToClaim(properties.benefit, id, productId, claimStep)      
          .then(data => {              
        this.setState({
          howToClaim: howToClaimLabels[data],
          displayHowToClaim: true,
          displayContinueBtn: claimStep ? claimStep < 1 : true
        });
      })
          .catch(error => {
              debugger;
        const { status, data } = (error || {}).response || {};
              if (status === 400) {
                  if (!this.claimstep > 1) {
                      hideTitle();
                  }
          this.setState({ modalDeclineMsg: data });
        } else {
          throw error;
        }
      });
  }

  static getHowToClaim(benefit, id, productId) {
    return HowToClaimApi.getHowToClaim(benefit, productId);
  }

    getDeclineModal() {
        debugger;
    return (
      <div>
        <CustomModal
          isOpen={!!this.state.modalDeclineMsg}
          contentLabel="Are you sure?"
          shouldCloseOnOverlayClick={false}
          titleClassName="claim-denied-title"
          overlayClassName="overlay"
          className="modal-frame"
          handleConfirmationOk={this.props.gotoLanding}
          handleConfirmationCancel={this.closeModal}
          title=""
          description={this.state.modalDeclineMsg}
          labelOk={this.props.t('commonOk')}
               /* labelCancel={this.state.modalDeclineMsg === "CHUBB_UNHANDLE_2025" ? this.props.t('commonCancel') : null}*/
        />
      </div>
    );
  }
  setBenefit() {
    const newClaim = {
      benefitId: this.props.properties.benefit
    };
    this.setState({ displayContinueBtn: false });
    this.props.updateClaimData(newClaim);
  }

  closeModal() {
    this.setState({
      modalDeclineMsg: ''
    });
  }

  render() {
    const { t } = this.props;
    let displayHowToClaim = '';
    let displayContinueBtn = '';

    if (this.state.displayHowToClaim) {
      const howToClaimWeAsk = (
        <HowToClaimWeAsk weAsk={this.state.howToClaim.weAsk} />
      );
      const howToClaimYouProvide = (
        <HowToClaimYouProvide youProvide={this.state.howToClaim.youProvide} />
      );
      const howToClaimInnerCards = (
        <HowToClaimInnerCards
          learnMoreTitle={t(this.state.howToClaim.buttonTitle)}
          learnMoreFooter={t(this.state.howToClaim.footer)}
          learnMoreModalFooter={t(this.state.howToClaim.learnMore.modalFooter)}
          learnMoreCardTitle={t(this.state.howToClaim.learnMore.title)}
          cardContainer={this.state.howToClaim.learnMore.cardContainer}
          modalButton={this.state.howToClaim.learnMore.modalButton}
        />
      );
      displayHowToClaim = (
        <CardHowToClaimComponent>
          <h3>{t(this.state.howToClaim.title)}</h3>
          {howToClaimWeAsk}
          {howToClaimYouProvide}
          {howToClaimInnerCards}
        </CardHowToClaimComponent>
      );

      displayContinueBtn = (
        <button
          data-testid="btn-howToClaim-continue"
          type="submit"
          className="btn btn-primary btn-lg btn-block mt-3 btn-amex-continue"
          onClick={() => this.setBenefit()}
        >
          {t(this.state.howToClaim.continueButton)}
        </button>
      );
    }

    return (
      <div className="row">
        <div className="col how-to-claim">
          {this.state.displayHowToClaim && displayHowToClaim}
          {this.state.displayContinueBtn && displayContinueBtn}
          {this.getDeclineModal()}
        </div>
      </div>
    );
  }
}

HowToClaim.propTypes = {
  t: PropTypes.func,
  id: PropTypes.number,
  updateClaimData: PropTypes.func,
  properties: PropTypes.shape({
    benefit: PropTypes.number
  }).isRequired,
  productId: PropTypes.number.isRequired,
  claimStep: PropTypes.number.isRequired,
  gotoLanding: PropTypes.func.isRequired,
  hideTitle: PropTypes.func.isRequired
};

HowToClaim.defaultProps = {
  t: result => result,
  id: null,
  updateClaimData: () => {}
};

export default withTranslation()(HowToClaim);
