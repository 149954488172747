import { notifyWarning } from 'actions/core/ui';
import { VALIDATE_DATES_REQUEST } from 'actions/feature/dates';

export const CREATE_CLAIM_REQUESTED = '[claim] create';
export const CREATE_CLAIM_SUCCEEDED = '[claim] create success';
export const CREATE_CLAIM_FAILED = '[claim] create fail';

export const UPDATE_CLAIM = '[claim] update';

export const SAVE_CLAIM_REQUESTED = '[claim] save';
export const SAVE_CLAIM_SUCCEEDED = '[claim] save success';
export const SAVE_CLAIM_FAILED = '[claim] save fail';

export const SAVE_CLAIM_AND_CONTINUE_REQUESTED = '[claim] save and continue';
export const SAVE_CLAIM_AND_CONTINUE_SUCCEEDED =
  '[claim] save and continue success';
export const SAVE_CLAIM_AND_CONTINUE_FAILED = '[claim] save and continue fail';

export const DECLINE_CLAIM_REQUESTED = '[claim] decline';
export const DECLINE_CLAIM_SUCCEEDED = '[claim] decline success';
export const DECLINE_CLAIM_FAILED = '[claim] decline fail';

export const GET_DOCUMENTS_REQUESTED = '[claim] get documents';
export const GET_DOCUMENTS_SUCCEEDED = '[claim] get success';
export const GET_DOCUMENTS_FAILED = '[claim] get fail';

export const SET_CLAIM_DOCUMENTS_LIST = '[claim] get documents list';
export const CLAIM_SELECT_FOR_DOCUMENTS_LIST =
  '[claim] selected to upload documents';

export const SUBMIT_CLAIM_REASON = '[claim] submit reason for no doc provided';
export const PROVIDE_REASON_SUCCEEDED =
  '[claim] submit reason for no doc provided Succeeded';
export const PROVIDE_REASON_FAILED =
  '[claim] submit reason for no doc provided Failed';

export const ADD_AFFECTED_PERSON = '[claim] Adding affected person';
export const REMOVE_AFFECTED_PERSON = '[claim] Removing affected person';
export const UPDATE_AFFECTED_PERSON = '[claim] Updating affected person';

export const ADD_AFFECTED_PERSON_REQUESTED =
  '[claim] Requested to add affected person';

export const UPDATE_AFFECTED_PERSON_REQUESTED =
  '[claim] Requested to update affected person';
export const REMOVE_AFFECTED_PERSON_REQUESTED =
  '[claim] Requested to remove affected person';

export const updateClaim = claimData => ({
  type: UPDATE_CLAIM,
  payload: claimData
});

export const createClaim = (claimData, redirectTo) => ({
  type: CREATE_CLAIM_REQUESTED,
  payload: claimData,
  meta: { redirectTo }
});

/**
 *
 * @param {number} currentStep
 * @param {bool} sendEmail
 */
//export const saveClaim = (
//  currentStep,
//  sendEmail = false,
//  notShowSpinner = false,
//  successNotify = notifyWarning('alertSuccessSave')
//) => saveClaimEvent(sendEmail, { currentStep, notShowSpinner, successNotify });

const BENEFITS_OF_CHUBB = process.env.REACT_APP_BENEFITS_OF_CHUBB;

export const saveClaim = (
    currentStep,
    benefitId,
    sendEmail = false,
    notShowSpinner = false
) => {
    // Get the Benefits from config file i.e. (.env/ .env.prod) and check if the  Benefit is CHUBB Product
    const benefits = BENEFITS_OF_CHUBB.split(',').map(Number);

    let isCHUBBProductBenefit = false;

    for (let i = 0; i < benefits.length; i++) {
        console.log('inside for benefit id ' + benefits[i]);
        if (benefits[i] === benefitId)
        {
            console.log('inside if ..' + benefits[i]);
            isCHUBBProductBenefit = true;
            break;
        }
    }

    // Get the current date
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // Months are zero-indexed (0 = January, 11 = December)
    const currentYear = currentDate.getFullYear();

    // Check if the current month is December and the year is 2024
    const isDecember2024 = (currentMonth === 10 && currentYear === 2024);
    const successNotify = (isCHUBBProductBenefit && isDecember2024)
        ? notifyWarning('newWarningMsgforChubb')
        : notifyWarning('alertSuccessSave');

    return saveClaimEvent(sendEmail, { currentStep, notShowSpinner, successNotify });
};


export const saveClaimEvent = (sendEmail, meta, onSuccess, onFail) => ({
  type: SAVE_CLAIM_REQUESTED,
  payload: { sendEmail },
  meta: { ...meta, onSuccess, onFail }
});

export const saveClaimAndContinue = () => ({
  type: SAVE_CLAIM_AND_CONTINUE_REQUESTED
});

/**
 *
 * @param {bool} sendEmail
 */
export const saveClaimAndCheckDates = (sendEmail = false) => ({
  type: SAVE_CLAIM_REQUESTED,
  payload: { sendEmail },
    meta: { onSaveClaimSuccess: VALIDATE_DATES_REQUEST }
});

/**
 *
 * @param {bool} sendEmail
 * @param {number} reasonId
 * @param {number} languageId
 * @param {number} cancelType
 */
export const saveClaimAndDecline = (
  sendEmail,
  reasonId,
  languageId,
  cancelType = null
) => ({
  type: SAVE_CLAIM_REQUESTED,
  payload: { sendEmail },
  meta: {
    onSaveClaimSuccess: DECLINE_CLAIM_REQUESTED,
    reasonId,
    languageId,
    cancelType
  }
});

export const setClaimDocumentsList = claimDocuments => {
  return { type: SET_CLAIM_DOCUMENTS_LIST, payload: claimDocuments };
};

export const selectClaimDocumentsList = claim => {
  return { type: CLAIM_SELECT_FOR_DOCUMENTS_LIST, payload: claim };
};

export const submitClaimReason = (claimId, reason, treeQuestionId = null) => {
  return {
    type: SUBMIT_CLAIM_REASON,
    payload: { claimId, reason, treeQuestionId }
  };
};

export const provideReasonForDocAbsense = (
  claimId,
  reason,
  questionId,
  answerType
) => {
  return {
    type: SUBMIT_CLAIM_REASON,
    payload: { claimId, questionId, reason, answerType }
  };
};

export const getDocuments = claimId => ({
  type: GET_DOCUMENTS_REQUESTED,
  payload: claimId
});

export const addAffectedPerson = person => ({
  type: ADD_AFFECTED_PERSON,
  payload: person
});

export const updateAffectedPerson = person => ({
  type: UPDATE_AFFECTED_PERSON,
  payload: person
});

export const removeAffectedPerson = id => ({
  type: REMOVE_AFFECTED_PERSON,
  payload: id
});

export const addAffectedPersonRequested = person => ({
  type: ADD_AFFECTED_PERSON_REQUESTED,
  payload: person
});

export const updateAffectedPersonRequested = people => ({
  type: UPDATE_AFFECTED_PERSON_REQUESTED,
  payload: people
});

export const removeAffectedPersonRequested = id => ({
  type: REMOVE_AFFECTED_PERSON_REQUESTED,
  payload: id
});
