import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { useDispatch } from 'react-redux';
import PageManager from 'containers/PageManager';
import AutoLogout from 'shared/containers/AutoLogout';
import ResetPasswordPage from 'containers/ResetPasswordPage';
import BodiamLoginPage from 'Bodiam/components/Login';
import MarketNotFoundPage from 'components/MarketNotFoundPage';
import MainPage from 'Bodiam/containers/MainPage';
import MedicalProvidersPage from 'Bodiam/containers/MedicalProvidersPage';
import YourCoverPage from 'Bodiam/containers/YourCoverPage';
import FAQPage from 'Bodiam/containers/FAQPage';
import CertificatePage from 'Bodiam/containers/CertificatePage';
import PrivateRoute from 'shared/components/PrivateRoute';
import ExternalRedirect from 'shared/components/ExternalRedirect';
import BodiamLayout from 'Bodiam/components/Layout';
import PageTitle from 'components/PageTitle/PageTitle';
import { Provider, useMarketConfiguration } from 'ConfigurationProvider';
import history from 'utils/history';
import { oneTrustCookiesInitialize } from '@contact_services/one-trust-cookies';
import { isTestEnvironment } from 'utils/sessionController';
import CookiesManager from 'components/CookiesManager';

const main =(
  <AutoLogout>
    <PageManager />
  </AutoLogout>
);

const App = ({ marketCode }) => {
  const [config] = useMarketConfiguration(marketCode);
  const dispatch = useDispatch();
  useEffect(() => {
    if (config) {
      dispatch(
        oneTrustCookiesInitialize(
          config.ONE_TRUST_DATA_DOMAIN_ID,
          null,
          isTestEnvironment()
        )
      );
    }
  }, [config, dispatch]);
  return (
    config && (
      <Provider value={config}>
        <PageTitle />
        <Router history={history}>
          <Switch>
            <ExternalRedirect
              path="/OLC_V3/portal/(amexuk|amexfr)"
              href="https://my-insurance.americanexpress.com"
            />
            <ExternalRedirect
              path="/OLC_V3/portal/amexde"
              href="https://www.americanexpress.com/de/versicherungsportal"
            />
            <Route exact path="/" component={MarketNotFoundPage} />
            <Route exact path="*/welcome/:market/dataprotection/:languageId" render={() => main}  />
            <Route exact path="*/welcome/:market" render={() => main}  />
            <Route exact path="/cookies" component={CookiesManager} />
            <Route
              path="*/recover/:market/:app/lang/:languageId"
              component={ResetPasswordPage}
            />
            <BodiamLayout>
              <PrivateRoute
                exact
                path="*/portal/:market"
                component={MainPage}
              />
              <PrivateRoute
                exact
                path="*/portal/:market/lang/:languageId"
                component={MainPage}
              />
              <Route
                exact
                path="*/portal/:market/login"
                component={BodiamLoginPage}
              />
              <Route
                exact
                path="*/portal/:market/login/lang/:languageId"
                component={BodiamLoginPage}
              />

              <PrivateRoute
                path="*/portal/:market/providers"
                component={MedicalProvidersPage}
              />
              <PrivateRoute
                path="*/portal/:market/certificate"
                component={CertificatePage}
              />
              <PrivateRoute
                exact
                path="*/portal/:market/your-cover"
                component={YourCoverPage}
              />
              <PrivateRoute
                path="*/portal/:market/your-cover/who"
                component={FAQPage}
              />
              <PrivateRoute
                path="*/portal/:market/your-cover/what"
                component={FAQPage}
              />
              <PrivateRoute
                path="*/portal/:market/your-cover/claim-process"
                component={FAQPage}
              />
              <PrivateRoute
                path="*/portal/:market/your-cover/privacy"
                component={FAQPage}
              />
            </BodiamLayout>
          </Switch>
        </Router>
      </Provider>
    )
  );
};

App.propTypes = {
  marketCode: PropTypes.string.isRequired
};

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
